import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { ArrowLeft, Github, ExternalLink, Code, Zap, Target, BookOpen } from 'lucide-react';

const ProjectDetails = () => {
  const { id } = useParams();
  
  // In a real application, you would fetch this data from an API or database
  const projectDetails = {
    1: {
      name: "Ainder",
      category: "Social Networking, AI Interaction",
      image: "assets/Ainder_Cover.png",
      description: "Ainder is a groundbreaking app that uses AI to simulate real human interaction. Similar to Tinder, users can swipe right or left to choose their preferred AI character for engaging conversations, making social interaction more dynamic and fun.",
      story: "Ainder was developed while I was working at Ouaz Games. Recognizing the potential of AI in enhancing social experiences, our team set out to create an app that allows users to connect with lifelike AI characters. By integrating advanced AI algorithms, we aimed to provide a unique platform where users could enjoy meaningful interactions. The project evolved into a feature-rich application with real-time messaging and payment integration for seamless user experience on both Google Play and the App Store.",
      challenges: [
        "Creating lifelike AI interactions that feel authentic and engaging",
        "Implementing real-time messaging for smooth communication",
        "Ensuring data privacy and security for user interactions"
      ],
      solutions: [
        "Developed sophisticated AI algorithms to enhance character interactions",
        "Utilized Firebase for real-time messaging capabilities",
        "Implemented robust security measures and user consent protocols to protect personal data"
      ],
      technologies: [
        "Flutter",
        "Firebase",
        "RESTful APIs",
        "AI Integration",
        "Cloud Storage",
        "Payment Integration"
      ],
      githubLink: null,
      liveLink: null
    },
    2: {
      name: "Retailink",
      categor: "Supply Chain Management, Logistics",
      image: "assets/Retailink_Cover.png",
      description: "Retailink is a comprehensive app designed for Eadis, a leading distributor of tobacco products across Africa. The app streamlines the delivery system and connects wholesalers, Points de Vente (PDVs), and delivery personnel for efficient operations.",
      story: "Retailink was conceived to address the challenges faced by Eadis in managing its vast distribution network across multiple countries in Africa. The idea emerged from the need for a centralized platform to enhance communication and logistics between wholesalers and PDVs. With a dedicated team, we set out to create an app that not only optimizes delivery routes but also provides valuable insights into sales trends and inventory management. The project evolved from an initial concept into a powerful tool that transforms how Eadis operates in markets like Mali, Mauritania, and Morocco.",
      challenges: [
        "Coordinating deliveries across diverse regions with varying infrastructure",
        "Integrating real-time tracking for improved logistics management",
        "Ensuring data accuracy and security for sensitive business information"
      ],
      solutions: [
        "Developed a centralized dashboard for real-time monitoring of deliveries",
        "Utilized advanced routing algorithms to optimize delivery paths",
        "Implemented robust data security measures and regular audits to protect information"
      ],
      technologies: [
        "Flutter",
        "Firebase",
        "RESTful APIs",
        "SQLite",
        "Geolocation Services",
        "Data Analytics"
      ],
      githubLink: null,
      liveLink: null
    },
    4: {
      name: "Accredited Wallet",
      category: "Finance, Mobile Wallet",
      image: "assets/Accredited_Wallet_Cover.png",
      description: "Accredited Wallet is your safe deposit box for shares, enabling users to connect their mobile wallet to a Brokerbot for easy stock purchases. The app ensures that all transactions are automatically recorded in the company’s share register, providing seamless management of your investments.",
      story: "The Accredited Wallet project was developed while I was working full-time at Vodinit. Recognizing the need for a secure and transparent way to manage stock investments, our team set out to create a mobile wallet that integrates with a Brokerbot. By utilizing blockchain technology, we aimed to enhance security and transparency in tracking shares. The project evolved into a robust application that allows users to buy stocks, monitor their investments, and access their shares anytime, with all transactions securely logged on an electronic ledger.",
      challenges: [
        "Ensuring data security and compliance with financial regulations",
        "Integrating blockchain technology for real-time updates",
        "Providing a user-friendly interface for complex financial transactions"
      ],
      solutions: [
        "Implemented robust encryption and authentication methods to safeguard user data",
        "Utilized Spring Boot for the backend to ensure efficient integration with blockchain ledgers",
        "Designed an intuitive interface in Flutter to simplify the user experience for managing investments"
      ],
      technologies: [
        "Flutter",
        "Spring Boot",
        "Blockchain Technology",
        "RESTful APIs",
        "SQLite"
      ],
      githubLink: null,
      liveLink: "https://apps.apple.com/us/app/accredited-wallet/id1560868130"
    }
    
  };

  const project = projectDetails[id];

  if (!project) {
    return <div className="text-center text-2xl text-gray-400 mt-20">Project not found</div>;
  }

  return (
    <div className="bg-gray-900 text-white min-h-screen">
      <div className="max-w-5xl mx-auto px-4 py-16">
        <Link to="/" className="text-green-400 flex items-center mb-8 hover:underline">
          <ArrowLeft className="mr-2" size={20} />
          Back to Portfolio
        </Link>
        
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="space-y-12"
        >
          <header className="text-center">
            <h1 className="text-5xl font-bold mb-4">{project.name}</h1>
            <p className="text-2xl text-gray-400 mb-8">{project.category}</p>
            {/* <img src={project.image} alt={project.name} className="w-full h-80 object-cover rounded-xl shadow-lg mb-8" /> */}
          </header>
          
          <div className="bg-gray-800 rounded-lg p-6 shadow-md">
            <h2 className="text-2xl font-semibold mb-4 flex items-center">
              <Code className="mr-2" /> Project Overview
            </h2>
            <p className="text-gray-300 text-lg leading-relaxed">{project.description}</p>
          </div>

          <div className="bg-gray-800 rounded-lg p-6 shadow-md">
            <h2 className="text-2xl font-semibold mb-4 flex items-center">
              <BookOpen className="mr-2" /> Project Story
            </h2>
            <p className="text-gray-300 text-lg leading-relaxed">{project.story}</p>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="bg-gray-800 rounded-lg p-6 shadow-md">
              <h2 className="text-2xl font-semibold mb-4 flex items-center">
                <Target className="mr-2" /> Challenges
              </h2>
              <ul className="space-y-2 text-gray-300">
                {project.challenges.map((challenge, index) => (
                  <li key={index} className="flex items-start">
                    <span className="text-green-400 mr-2">•</span>
                    {challenge}
                  </li>
                ))}
              </ul>
            </div>
            <div className="bg-gray-800 rounded-lg p-6 shadow-md">
              <h2 className="text-2xl font-semibold mb-4 flex items-center">
                <Zap className="mr-2" /> Solutions
              </h2>
              <ul className="space-y-2 text-gray-300">
                {project.solutions.map((solution, index) => (
                  <li key={index} className="flex items-start">
                    <span className="text-green-400 mr-2">•</span>
                    {solution}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          
          <div className="bg-gray-800 rounded-lg p-6 shadow-md">
            <h2 className="text-2xl font-semibold mb-4">Technologies Used</h2>
            <div className="flex flex-wrap gap-2">
              {project.technologies.map((tech, index) => (
                <span key={index} className="bg-green-400 text-black px-3 py-1 rounded-full text-sm">
                  {tech}
                </span>
              ))}
            </div>
          </div>
          
          <div className="flex justify-center space-x-6">
            {project.githubLink && <a
              href={project.githubLink}
              target="_blank"
              rel="noopener noreferrer"
              className="bg-gray-700 hover:bg-gray-600 text-white px-6 py-3 rounded-lg font-semibold text-lg transition-colors inline-flex items-center"
            >
              <Github className="mr-2" size={20} />
              View on GitHub
            </a>}
            {project.liveLink && <a
              href={project.liveLink}
              target="_blank"
              rel="noopener noreferrer"
              className="bg-green-500 hover:bg-green-600 text-black px-6 py-3 rounded-lg font-semibold text-lg transition-colors inline-flex items-center"
            >
              <ExternalLink className="mr-2" size={20} />
              Live Demo
            </a>}
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default ProjectDetails;