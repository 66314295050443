import { useState } from 'react';
import { motion } from 'framer-motion';

const CodeElement = () => {
    const [activeTab, setActiveTab] = useState(0);
    const codeSnippets = [
      {
        language: 'Dart',
        code: `
class FlutterDev extends Developer {
  @override
  Widget build(BuildContext context) {
    return Scaffold(
      appBar: AppBar(title: Text('Reda Antar')),
      body: Center(
        child: Text('Building amazing mobile apps!'),
      ),
    );
  }
}`,
      },
      {
        language: 'Java',
        code: `
@RestController
@RequestMapping("/api")
public class SpringController {
    @GetMapping("/greeting")
    public String greeting() {
        return "Hello from Reda's Spring Backend!";
    }
}`,
      },
      {
        language: 'JavaScript',
        code: `
const RedaComponent = () => {
  const [skills, setSkills] = useState([
    'React', 'Flutter', 'Spring'
  ]);

  return (
    <div>
      <h1>Reda Antar</h1>
      <ul>
        {skills.map(skill => (
          <li key={skill}>{skill}</li>
        ))}
      </ul>
    </div>
  );
};`,
      },
    ];

    return (
      <div className="hidden lg:block absolute right-0 top-1/2 transform -translate-y-1/2 w-1/2 max-w-lg">
        <motion.div 
          className="bg-gray-800 rounded-lg shadow-xl overflow-hidden"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <div className="flex bg-gray-900 px-4 py-2">
            {codeSnippets.map((snippet, index) => (
              <button
                key={snippet.language}
                className={`px-3 py-1 rounded-t-lg text-sm font-medium ${
                  activeTab === index ? 'bg-gray-800 text-white' : 'text-gray-400 hover:text-white'
                }`}
                onClick={() => setActiveTab(index)}
              >
                {snippet.language}
              </button>
            ))}
          </div>
          <motion.pre
            key={activeTab}
            className="p-4 text-sm text-green-400 overflow-x-auto"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3 }}
          >
            <code>{codeSnippets[activeTab].code}</code>
          </motion.pre>
        </motion.div>
      </div>
    );
  };

  export default CodeElement;